.filter {
  padding-right: 0.2rem;
  min-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.filter::-webkit-scrollbar {
  width: 0.12rem;
  border-radius: 0.06rem;
  background-color: #e5e5e5;
}

.filter::-webkit-scrollbar-thumb {
  width: 100%;
  border-radius: 0.03rem;
  background-color: #f6a75c;
}

h2 {
  font-size: 0.18rem;
  color: #000000;
  margin-bottom: 0.2rem;
  font-weight: bold;
}

:deep(.ant-input-search) {
  width: 100%;
}

:deep(.ant-input-search) .ant-input {
  border-color: transparent;
}

:deep(.ant-input-search):hover input {
  border-color: #79f9d9 !important;
}

.result {
  padding: 0.1rem 0;
  border-bottom: 0.01rem solid #e5e5e5;
  font-size: 0.14rem;
  color: #333333;
  line-height: 2;
}

.result .red {
  color: red;
}

.nodata {
  padding-top: 0.2rem;
  font-size: 0.14rem;
  color: #bbbbbb;
  line-height: 2;
}

.list {
  min-height: 1.5rem;
  margin-bottom: 0.3rem;
}

.list {
  flex: 1;
  display: flex;
  flex-direction: column;
}

:deep(.ant-list) {
  flex: 1;
}

:deep(.ant-spin-nested-loading) {
  min-height: 1rem;
}

:deep(.ant-list-item) {
  padding: 0.15rem 0;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
}

:deep(.ant-list-item) .title {
  margin-bottom: 0.1rem;
  margin-bottom: 0.04rem;
  color: rgba(0, 0, 0, 0.65);
  font-size: 0.14rem;
  line-height: 0.22rem;
  align-items: center;
  width: 100%;
}

:deep(.ant-list-item) .title .dot {
  flex: 1;
}

:deep(.ant-list-item) .title .svg-icon {
  color: rgba(0, 0, 0, 0.65);
  transition: .3s;
}

:deep(.ant-list-item) .title .svg-icon:hover {
  color: #f6a75c;
}

:deep(.ant-list-item) .title a {
  color: rgba(0, 0, 0, 0.65);
}

:deep(.ant-list-item) .des {
  color: rgba(0, 0, 0, 0.45);
  font-size: 0.14rem;
  line-height: 0.22rem;
}

:deep(.ant-list-item) .des a {
  color: rgba(0, 0, 0, 0.45);
  text-decoration: none !important;
}

.search-box {
  display: flex;
}

.search-box :deep(.ant-select) {
  width: 0.9rem;
  flex-shrink: 0;
  margin-right: 0.1rem;
}

.search-box :deep(.ant-select-selection) {
  height: 0.32rem;
  line-height: 0.32rem;
}

.search-box :deep(.ant-select-selection__rendered) {
  height: 0.32rem;
  line-height: 0.32rem;
  margin-left: 0.1rem;
  margin-right: 0.05rem;
  font-size: 0.12rem;
}

.search-box :deep(.ant-select-arrow) {
  right: 0.05rem;
}

:deep(.ant-pagination-prev),
:deep(.ant-pagination-next),
:deep(.ant-pagination-item) {
  width: 0.3rem;
  height: 0.3rem !important;
  line-height: 0.3rem !important;
}

:deep(.ant-pagination-prev) {
  margin-right: 0.1rem;
}

:deep(.ant-pagination-next) {
  margin: 0;
}

:deep(.ant-btn-primary) {
  background-color: #79f9d9;
}

.title {
  display: flex;
  justify-content: space-between;
}

.ant-list-item {
  border-left: 0.03rem solid var(--color);
  margin-bottom: 0.15rem;
  padding-left: 0.15rem;
}