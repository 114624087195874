.section {
  display: flex;
  border-radius: 0.1rem;
  background-color: #fff;
  height: 100%;
  position: relative;
}

.model_info {
  padding: 0.2rem 0.2rem 0.5rem 0.4rem;
  flex: 1;
  flex-shrink: 1;
  width: 0;
  display: flex;
  flex-direction: column;
  position: relative;
}

.model_info .content {
  position: absolute;
  width: 100%;
  height: calc(100% - 0.5rem);
  top: 0.53rem;
  left: 0;
  padding: 0 0.2rem 0 0;
}

.itemLast {
  position: relative;
}

.itemLast .qrdode {
  position: absolute;
  right: calc(100% + 0.1rem);
  background: #fff;
  top: 0;
  padding-bottom: 0.15rem;
  border-radius: 0.05rem;
  z-index: 99;
  box-shadow: 0.02rem 0.03rem 0.1rem rgba(0, 0, 0, 0.3);
  width: 1.6rem;
  color: #333;
  opacity: 0;
  transition: all 0.4s ease-out;
  transform: translateX(0.1rem);
  visibility: hidden;
}

.itemLast:hover .qrdode {
  opacity: 1;
  transform: translateX(0);
  visibility: visible;
}

.swdt {
  margin-bottom: 0.12rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.swdt .item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.swdt .item span {
  font-size: 0.875em;
}

.swdt .item .svg-icon {
  font-size: 1em;
  margin-right: 0.07rem;
}

.tab {
  position: absolute;
  left: calc(100% + 0.05rem);
  top: 0;
  display: flex;
  flex-direction: column;
}

.tab .item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0.6rem;
  height: 0.6rem;
  margin-bottom: 0.01rem;
  border-radius: 0.03rem;
  font-size: 0.18rem;
  color: rgba(255, 255, 255, 0.6);
  background-color: rgba(255, 255, 255, 0.2);
  transition: 0.3s;
  padding: 0 0.1rem;
  line-height: 1.2;
  text-align: center;
  cursor: pointer;
}

.tab .item.active,
.tab .item:hover {
  color: #000;
  background-color: #00ffc0;
}

.right {
  transition: 0.3s;
  overflow: hidden;
  width: 0;
  flex-shrink: 0;
  border-radius: 0.1rem;
  box-shadow: 0 0 0.2rem 0 rgba(153, 153, 153, 0.3);
  background-color: #fafafb;
  position: relative;
  overflow-y: auto;
}

.right::-webkit-scrollbar {
  width: 0.06rem;
  border-radius: 0.03rem;
  background-color: #e5e5e5;
}

.right::-webkit-scrollbar-thumb {
  width: 100%;
  border-radius: 0.03rem;
  background-color: #f6a75c;
}

.right .inner {
  width: 3rem;
  padding: 0.2rem 0 0.2rem 0.2rem;
  position: absolute;
  left: 0;
  height: 100%;
}

.right.active {
  width: 3rem;
}

@media (max-width: 1024px) {
  .model_info {
    padding: 0.2rem 0.15rem;
  }

  .model_info .content {
    padding: 0;
  }

  .tab {
    display: none;
  }

  .swdt {
    display: none;
  }
}