.model {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  transition: 0.3s;
  background-color: #000;
}

.model > .auto-1440 {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.model .title,
.model /deep/ .breadcrumb {
  flex-shrink: 0;
}

.title {
  display: flex;
  color: #fff;
  justify-content: space-between;
  margin-top: 0.2rem;
  margin-bottom: 0.1rem;
  align-items: flex-end;
}

.title h1 {
  color: #fff;
  font-size: 1.25em;
  line-height: 0.35rem;
  line-height: 1.35;
}

.title .goback {
  line-height: 0.35rem;
  font-size: 1em;
  color: #fff;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
  transition: 0.3s;
}

.title .goback .svg-icon {
  font-size: 0.5625em;
  margin-left: 0.1rem;
}

.title .goback:hover {
  color: #f49133;
}

.content {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.content .type {
  position: absolute;
  right: calc(100% + 0.05rem);
  top: 0;
  display: flex;
  flex-direction: column;
}

.content .type .item {
  padding: 0.1rem 0.15rem;
  line-height: 0.3rem;
  text-align: center;
  white-space: nowrap;
  background-color: rgba(255, 255, 255, 0.2);
  margin-bottom: 0.05rem;
  border-radius: 0 0.1rem 0 0.1rem;
  color: rgba(255, 255, 255, 0.6);
  transition: 0.3s;
  font-size: 0.18rem;
  cursor: pointer;
  font-weight: bold;
}

.content .type .item.active,
.content .type .item:hover {
  color: #000;
  background-color: #00ffc0;
}

.content .tree {
  flex: 1;
  flex-shrink: 1;
  padding-bottom: 0.4rem;
}

.content .tree /deep/ .ant-spin-container,
.content .tree /deep/ .ant-spin-nested-loading {
  height: 100%;
}

.qrdode {
  position: absolute;
  left: calc(100% + 0.1rem);
  z-index: 2;
  bottom: 0.4rem;
  color: #fff;
  text-align: center;
  white-space: nowrap;
}

.qrdode img {
  border-radius: 0.04rem;
  margin-bottom: 0.1rem;
  width: 1.5rem;
  display: block !important;
  margin: 0 auto 0.1rem;
}

@media (max-width: 1024px) {
  .content .type {
    display: none;
  }

  .tab {
    display: none;
  }

  .qrdode {
    display: none;
  }

  .goback {
    display: none !important;
  }

  /deep/ .breadcrumb {
    display: none !important;
  }
}