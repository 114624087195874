:deep(.ant-tree-switcher) {
  display: none !important;
}

:deep(.ant-tree-child-tree) {
  padding: 0 !important;
}

:deep(.ant-tree-child-tree) li {
  padding: 0 !important;
}

.tree_title {
  font-size: 0.16rem;
  color: #333333;
  line-height: 2;
  white-space: pre-wrap;
  display: block;
  margin-bottom: 0.1rem;
}

:deep(.ant-tree) > li {
  padding: 0 !important;
}

:deep(.ant-tree) > li span {
  height: auto;
  padding: 0;
  border: 0 !important;
}

.radiogrop {
  margin-bottom: 0.2rem;
}

.radiogrop :deep(.ant-radio-wrapper) {
  margin-right: 0.5rem;
}

.radiogrop :deep(.ant-radio-wrapper):last-of-type {
  margin-right: 0;
}

.radiogrop :deep(.ant-radio-checked) .ant-radio-inner {
  border-color: #f6a75c;
}

.radiogrop :deep(.ant-radio-checked) .ant-radio-inner::after {
  background-color: #f6a75c;
}

.right-tree {
  overflow-y: auto;
  padding-right: 0.2rem;
}

.right-tree :deep(.ant-tree-node-content-wrapper) {
  width: 100% !important;
  display: block;
}

.right-tree::-webkit-scrollbar {
  width: 0.06rem;
  border-radius: 0.03rem;
  background-color: #e5e5e5;
}

.right-tree::-webkit-scrollbar-thumb {
  width: 100%;
  border-radius: 0.03rem;
  background-color: #f6a75c;
}

.right-tree .tree_title {
  font-size: 0.16rem;
  color: #000000;
  line-height: 1.5;
  display: flex;
  justify-content: space-between;
}

.right-tree .tree_title .svg-icon {
  margin-left: 0.2rem;
  color: #888;
  transform-origin: 50%;
  transition: 0.3s;
}

.right-tree .ant-tree-node-content-wrapper-open .svg-icon {
  transform: rotate(90deg);
}

.right-tree .ant-tree-node-content-wrapper-normal .svg-icon {
  display: none;
}

:deep(.ant-tree) > li {
  margin-bottom: 0.25rem;
}

:deep(.ant-tree) > li > .ant-tree-node-content-wrapper .tree_title .svg-icon {
  font-size: 0.11rem;
  margin-top: 0.06rem;
}

:deep(.ant-tree) > li > ul {
  background-color: #fff;
  padding: 0.1rem !important;
}

:deep(.ant-tree) > li > ul .tree_title {
  font-size: 0.14rem;
  line-height: 1.714;
  color: #888888;
}

:deep(.ant-tree) > li > ul .tree_title .svg-icon {
  font-size: 0.08rem;
  margin-top: 0.08rem;
}

:deep(.ant-pagination-prev),
:deep(.ant-pagination-next),
:deep(.ant-pagination-item) {
  width: 0.3rem;
  height: 0.3rem !important;
  line-height: 0.3rem !important;
}

:deep(.ant-pagination-prev) {
  margin-right: 0.1rem;
}

:deep(.ant-pagination-next) {
  margin: 0;
}